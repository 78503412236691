import { useEffect, useState } from "react";

import {
  IonButton,
  IonContent,
  IonHeader,
  IonList,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./Contacts.css";

import { getJson } from "../utils";

type Contact = {
  e164: string;
  label: string;
  facetime: boolean;
  whatsapp: boolean;
  phone: boolean;
  text: boolean;
};

const Contacts: React.FC = () => {
  const [contacts, setContacts] = useState<Contact[]>([]);

  useEffect(() => {
    (async function () {
      try {
        const json = await getJson(
          "https://cars-links-contacts.calendee.workers.dev/ud2deZoAJI3f31U3TjVZY",
        );

        if (json) {
          setContacts(json);
        }
      } catch (e) {
        alert("Fetching data failed");
      }
    })();
  }, []);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Contacts</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Contacts</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          {contacts.map((contact) => {
            return (
              <IonItem key={contact.e164}>
                <IonLabel>
                  <IonLabel>{contact.label}</IonLabel>
                  <div>
                    {contact.facetime ? (
                      <IonButton
                        color="primary"
                        href={`facetime://${contact.e164}`}
                      >
                        FaceTime
                      </IonButton>
                    ) : null}
                    {contact.whatsapp ? (
                      <IonButton
                        color="success"
                        href={`https://wa.me/${contact.e164}`}
                        target="_blank"
                      >
                        WhatsApp
                      </IonButton>
                    ) : null}
                    {contact.text ? (
                      <IonButton color="warning" href={`sms:${contact.e164}`}>
                        Message
                      </IonButton>
                    ) : null}
                    {contact.phone ? (
                      <IonButton color="warning" href={`tel://${contact.e164}`}>
                        Call
                      </IonButton>
                    ) : null}
                  </div>
                </IonLabel>
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Contacts;
