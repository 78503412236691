import { useEffect, useState } from "react";

import {
  IonContent,
  IonHeader,
  IonList,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./Links.css";

import { getJson } from "../utils";

type Link = {
  label: string;
  url: string;
};

const Links: React.FC = () => {
  const [links, setLinks] = useState<Link[]>([]);

  useEffect(() => {
    (async function () {
      try {
        const json = await getJson(
          "https://cars-links-links.calendee.workers.dev/hSO-uH_fDCcRXXd85Tt6l",
        );

        if (json) {
          setLinks(json);
        }
      } catch (e) {
        alert("Fetching data failed");
      }
    })();
  }, []);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Links</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Contacts</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          {links.map((link) => {
            return (
              <IonItem key={link.url} href={link.url} target="_blank">
                <IonLabel>{link.label}</IonLabel>
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Links;
